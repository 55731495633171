import { HttpClient, HttpParams } from '@angular/common/http';

import { CoreAuthLibClientService } from '@app/core-auth-lib/services/core-auth-lib-client.service';
import { Injectable } from '@angular/core';
import { ItineraryBuilderUtilsService } from './itinerary-builder-utils.service';
import { ItineraryItem } from '../interfaces/itinerary-item';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ItineraryBuilderItemClientService {

  private BASE_URL = environment.itineraryBuilderSDK.base_url;

  constructor(
    private http: HttpClient,
    private coreAuthLibClientService: CoreAuthLibClientService,
    private utils: ItineraryBuilderUtilsService
  ) { }

  /**
   * @param itineraryId the id of the itinerary
   * @param dayId the day id to add the item
   * @param listingId the listing id for the item or
   * @param eventId the event id for the item
   * @param startTime the start time in format 'HH:mm:ss'
   * @param endTime the end time in format 'HH:mm:ss'
   */
  async addItemToDay(itineraryId: number, dayId: number, listingId?: string, eventId?: string, startTime?: string, endTime?: string) {
    const token = await this.coreAuthLibClientService.getToken();
    const headers = this.utils.getAuthorizationTokenHeaders(token);
    const addItemToDayUrl = `${this.BASE_URL}/api/v1/itineraries/${itineraryId}/days/${dayId}/item`;
    const params = new HttpParams({
      fromObject: {
        ListingID: listingId,
        EventId: eventId,
        startTime, endTime
      }
    });
    const requestObservable = this.http.post<ItineraryItem>(addItemToDayUrl, params, { headers });
    return new Promise<ItineraryItem>((resolve, reject) => {
      requestObservable.subscribe(result => {
        if (result) {
          resolve(result);
        } else {
          reject('Unknow error');
        }
      }, err => {
        reject(err);
      });
    });
    // let aUrlString = DependencyManager.shared().baseUrl + "/api/v1/itineraries/\(itineraryId!)/days/\(dayId!)/item"
  }

  /**
   * @param itineraryId the id of the itinerary
   * @param dayId the day id to add the item
   * @param itemId the item id
   * @param listingId the listing id for the item or
   * @param eventId the event id for the item
   * @param startTime the start time in format 'HH:mm:ss'
   * @param endTime the end time in format 'HH:mm:ss'
   */
  async editItemToDay(
    itineraryId: number,
    dayId: number,
    itemId: number,
    listingId?: number,
    eventId?: number,
    startTime?: string,
    endTime?: string
  ) {
    const token = await this.coreAuthLibClientService.getToken();
    const headers = this.utils.getAuthorizationTokenHeaders(token);
    const editItemToDayUrl = `${this.BASE_URL}/api/v1/itineraries/${itineraryId}/days/${dayId}/item/${itemId}`;
    const param = {
      ListingId: listingId,
      EventId: eventId,
      StartTime: startTime,
      EndTime: endTime
    };
    //console.log(param);
    const requestObservable = this.http.put<{
      WasSuccessful: boolean
    }>(editItemToDayUrl, param, { headers });
    return new Promise<{ wasSuccessful: boolean }>((resolve, reject) => {
      requestObservable.subscribe(result => {
        if (result) {
          resolve({ wasSuccessful: result.WasSuccessful });
        } else {
          reject('Unknow error');
        }
      });
    });
  }

  /**
   * @param itineraryId the id of the itinerary
   * @param dayId the day id to add the item
   * @param itemId the item id
   */
  async deleteItemFromDay(itineraryId: number, dayId: number, itemId: number) {
    const token = await this.coreAuthLibClientService.getToken();
    const headers = this.utils.getAuthorizationTokenHeaders(token);
    const deleteItemToDayUrl = `${this.BASE_URL}/api/v1/itineraries/${itineraryId}/days/${dayId}/item/${itemId}`;
    const requestObservable = this.http.delete<{
      WasSuccessful: boolean
    }>(deleteItemToDayUrl, { headers });
    return new Promise<{ wasSuccessful: boolean }>((resolve, reject) => {
      requestObservable.subscribe(result => {
        if (result) {
          resolve({ wasSuccessful: result.WasSuccessful });
        } else {
          reject('Unknow error');
        }
      });
    });
  }
}
