import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LikedListingsComponent } from '@app/codebase/itineraries/liked-listings/liked-listings.component';
import { Listing } from '@app/core/data/Listing';
import { FavoritesService } from '@app/core/services/favorites.service';
import { environment } from '@env/environment';
import { ModalController } from '@ionic/angular';
declare function require(name:string);

@Component({
  selector: 'app-favorite-listings-horizontal',
  templateUrl: require('template/' + environment.template + '/html/favorite-listings-horizontal.component.html'),
  styles: [require('template/' + environment.template + '/styles/favorite-listings-horizontal.component.scss')]
})
export class FavoriteListingsHorizontalComponent implements OnInit {

  public environment = environment;  @Input() listings: Listing[];

  constructor(
    private favoritesService: FavoritesService,
    private route: ActivatedRoute,
    private modalController: ModalController,
  ) { }

  ngOnInit() {
    this.favoritesService.getFavoritesListings().then(list => {
      list.sort((a, b) => {
        if (a.Company > b.Company) {
          return 1;
        } else if (a.Company < b.Company) {
          return -1;
        }
        return 0;
      });

      this.listings = list;
    }).catch(err => {
      console.error(err);
    });
  }

  ionViewDidEnter(){
    this.ngOnInit();
    //console.log("ionViewDidEnter-------------------------")
  }

  async onFavoritesClick() {
    const modal = await this.modalController.create({
      component: LikedListingsComponent,
      componentProps: {
        itineraryId: this.route.snapshot.params.id,
        // dayId: this.day.Id,
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data && data.updated && data.itineraryItem) {
      // this.stops.push(data.itineraryItem);
    } else {
      //console.log('>>> Nothing changed');
    }
  }

}
