import { CoreAuthLibClientUtilsService } from './core-auth-lib-client-utils.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SecureSessionStorerService } from './secure-session-storer.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class CoreAuthLibClientService {

  private BASE_URL = environment.coreAuthLib.base_url;
  private accessToken?: {
    accessToken: string;
    tokenType: string;
    refreshToken: string;
    expiresAt: string;
    issuedAt: string;
  };

  constructor(
    private http: HttpClient,
    private sessionStorer: SecureSessionStorerService,
    private clientUtils: CoreAuthLibClientUtilsService) {
    this.getToken();
  }

  deleteSession() {
    this.accessToken = undefined;
    this.sessionStorer.deleteSession();
  }

  async storeSession(session: {
    access_token: string;
    token_type: string;
    refresh_token: string;
    expires_at: string;
    issued_at: string;
  }) {
    await this.sessionStorer.storeSession(session);
    this.accessToken = await this.sessionStorer.session();
    return true;
  }

  async getToken() {
    if (this.accessToken === undefined) { // Tal vez se tiene que cambiar a null
      try {
        this.accessToken = await this.sessionStorer.session();
        if (this.expired(this.accessToken)) {
          await this.refreshToken();
          return this.accessToken;
        }
        return this.accessToken; // Session not expired
      } catch (error) {
        await this.getApplicationToken();
        return this.accessToken;
      }
    } else {
      if (this.expired(this.accessToken)) {
        await this.refreshToken();
        return this.accessToken;
      }
      return this.accessToken;
    }
  }

  async getApplicationToken() {
    const url = `${this.BASE_URL}/api/v1/authentication/credentials`;
    return new Promise<{
      accessToken: string;
      tokenType: string;
      refreshToken: string;
      expiresAt: string;
      issuedAt: string;
    }>((resolve, reject) => {
      this.http.post<{
        access_token: string;
        token_type: string;
        refresh_token: string;
        expires_at: string;
        issued_at: string;
      }>(
        url,
        this.clientUtils.getApplicationTokenParams(),
        { headers: this.clientUtils.getApplicationTokenHeaders() }
      ).subscribe(async response => {
        if (response) {
          await this.sessionStorer.storeSession(response);
          await this.sessionStorer.setSessionType('application');
          this.accessToken = await this.sessionStorer.session();
          resolve(this.accessToken);
        } else {
          reject('cauthlib[apptoken]: Unknown error');
        }
      }, err => {
        reject(err);
      });
    });
  }



  async refreshToken() {
    const url = `${this.BASE_URL}/api/v1/authentication/credentials`;
    return new Promise<{
      accessToken: string;
      tokenType: string;
      refreshToken: string;
      expiresAt: string;
      issuedAt: string;
    }>((resolve, reject) => {
      this.http.post<{
        access_token: string;
        token_type: string;
        refresh_token: string;
        expires_at: string;
        issued_at: string;
      }>(
        url, // URL
        this.clientUtils.getRefreshTokenParams(this.accessToken.refreshToken), // Body Params
        { headers: this.clientUtils.getRefreshTokenHeaders() } // Headers
      ).subscribe(async response => {
        if (response) {
          this.sessionStorer.storeSession(response);
          this.accessToken = await this.sessionStorer.session();
          resolve(this.accessToken);
        } else {
          reject('cauthlib[refreshToken]: Unknown error');
        }
      }, err => {
        reject(err);
      });
    });
  }

  private expired(accessToken: {
    accessToken: string;
    tokenType: string;
    refreshToken: string;
    expiresAt: string;
    issuedAt: string;
  }) {
    const nowDate = new Date();
    const expirationDate = new Date(accessToken.expiresAt);
    // //console.log('Expiration date ');
    // //console.log(expirationDate);
    if (expirationDate < nowDate) { // Checar si crra fecha valida
      return true;
    } else {
      return false;
    }
  }
}
