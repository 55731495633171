import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UsersApiService } from '@app/core/users-api/users-api.service';
import { NavController, ModalController } from '@ionic/angular';
import { GenericAlertComponent } from '../generic-alert/generic-alert.component';

import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { CodebaseTranslateService } from '@app/services/codebase-translate.service';
import { HomePage } from '@app/codebase/home/home.page';
import { environment } from '@env/environment';
declare function require(name:string);

@Component({
  selector: 'app-main-top-bar-v2',
  templateUrl: require('template/' + environment.template + '/html/main-top-bar-v2.component.html'),
  styles: [require('template/' + environment.template + '/styles/main-top-bar-v2.component.scss')]
})
export class MainTopBarV2Component implements OnInit {

  public environment = environment;  @Input() headerTitle!: string;

  language: any;
  constructor(
    private router: Router,
    private usersApi: UsersApiService,
    private navCtrl: NavController,
    // private homePage: HomePage,
    private route: ActivatedRoute,
    public storage: Storage,
    public translateService: CodebaseTranslateService,
    public translate: TranslateService,
    private modalCtrlr: ModalController
  ) {
    // storage.get('language').then(lang => {
    //   //console.log(lang)
    //   translate.setDefaultLang(lang || 'en')
    //   this.language = lang
    // })
  }

  ngOnInit() {}

  onFocusSearch() {
    this.router.navigateByUrl('/tabs/search', {
      relativeTo: this.route
    });
  }
  async onInboxTap() {
    this.router.navigateByUrl('/tabs/inbox', {
      relativeTo: this.route
    });
  }

  async onUserTap() {
    this.usersApi.isLoggedIn().then(loggedIn => {
      if (loggedIn) {
        this.router.navigateByUrl(`/tabs/me`, {
          relativeTo: this.route
        });
      } else {
        this.showLoginAlert();
      }
    }).catch(err => {
      // error, not sure what to do here but let's show a login alert page
      this.showLoginAlert();
    });
  }

  async showLoginAlert() {
    // this.translateService.languageChange(this.homePage.languageSelected.val);
    const modal = await this.modalCtrlr.create({
      cssClass: 'my-custom-class',
      component: GenericAlertComponent,
      componentProps: {
        title: this.translateService.instant('MODAL_LOGIN.TITLE'),
        message: this.translateService.instant('MODAL_LOGIN.SUBTITLE'),
        buttons: [
          {
            title: this.translateService.instant('MODAL_LOGIN.BTN_LOGIN'),
            value: 'login'
          }, {
            title: this.translateService.instant('MODAL_LOGIN.BTN_CANCEL'),
            value: 'cancel'
          }
        ]
      }
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data && data.value === 'login') {
      return this.navCtrl.navigateRoot(['/login'], { queryParams: { redirectUrl: this.router.url } });
    } else {
      return '';
    }
  }

}
