import { AdditionalField, SocialNetwork, UserApiUser } from './User';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class SecureUserRepositoryService {
  public LOGIN_WITH_IN_HOUSE = 0;
  public LOGIN_WITH_FACEBOOK = 1;
  public LOGIN_WITH_GOOGLE = 2;
  public LOGIN_WITH_APPLE = 3;
  public KEYS = {
    Id: 'user_id',
    Email: 'user_email',
    ImageUrl: 'user_image_url',
    Duty: 'user_duty',
    AdditionalPropertyPrefix: 'user_ap_',
    FacebookName: 'user_facebook_name',
    FacebookImageUrl: 'user_facebook_image_url',
    GoogleName: 'user_google_name',
    GoogleImageUrl: 'user_google_image_url',
    AppleName: 'user_apple_name',
    AppleImageUrl: 'user_apple_image_url',
    loginWith: 'user_login_with',
    loginWithName: 'user_login_with_name',
  };

  private refreshSection: BehaviorSubject<any> = new BehaviorSubject(false);
  RefreshPage: Observable<any> = this.refreshSection.asObservable();

  constructor(
    private storage: Storage
  ) { }

  async clear(): Promise<void> {
    await this.storage.remove(this.KEYS.Id);
    await this.storage.remove(this.KEYS.Email);
    await this.storage.remove(this.KEYS.ImageUrl);
    await this.storage.remove(this.KEYS.Duty);
    await this.storage.remove(this.KEYS.FacebookName);
    await this.storage.remove(this.KEYS.FacebookImageUrl);
    await this.storage.remove(this.KEYS.GoogleName);
    await this.storage.remove(this.KEYS.GoogleImageUrl);
    await this.storage.remove(this.KEYS.AppleName);
    await this.storage.remove(this.KEYS.AppleImageUrl);
    await this.storage.remove(this.KEYS.loginWith);
    await this.storage.remove(this.KEYS.loginWithName);

    const keys = await this.storage.keys();
    const whiteListedKeys: string[] = [];
    if (keys) {
      keys.forEach(item => {
        if (item.includes(this.KEYS.AdditionalPropertyPrefix)) {
          whiteListedKeys.push(item);
        }
      });
    }

    whiteListedKeys.forEach(async key => {
      await this.storage.remove(key);
    });

    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  }

  async set(user: UserApiUser): Promise<void> {
    await this.storage.set(this.KEYS.Id, user.Id);
    await this.storage.set(this.KEYS.Email, user.Email);
    await this.storage.set(this.KEYS.ImageUrl, user.ImageUrl);
    await this.storage.set(this.KEYS.Duty, user.Duty);

    if (user.GooglePlus && !user.GooglePlus.DisplayName && !user.GooglePlus.ProfileImageUrl){
      user.GooglePlus.DisplayName = await this.storage.get(this.KEYS.GoogleName);
      user.GooglePlus.ProfileImageUrl = await this.storage.get(this.KEYS.GoogleImageUrl);
    }

    if (user.Apple && !user.Apple.DisplayName && !user.Apple.ProfileImageUrl){
      user.Apple.DisplayName = await this.storage.get(this.KEYS.AppleName);
      user.Apple.ProfileImageUrl = await this.storage.get(this.KEYS.AppleImageUrl);
    }

    if (user.AdditionalFields) {
      user.AdditionalFields.forEach(async item => {
        if (item) {
          await this.storage.set(`${this.KEYS.AdditionalPropertyPrefix}${item.Name}`, item.Value);
        }
      });
    }

    if (user.Facebook) {
      await this.storage.set(this.KEYS.FacebookName, user.Facebook.DisplayName);
      await this.storage.set(this.KEYS.FacebookImageUrl, user.Facebook.ProfileImageUrl);
    }

    if (user.GooglePlus) {
      await this.storage.set(this.KEYS.GoogleName, user.GooglePlus.DisplayName);
      await this.storage.set(this.KEYS.GoogleImageUrl, user.GooglePlus.ProfileImageUrl);
    }

    if (user.Apple) {
      await this.storage.set(this.KEYS.AppleName, user.Apple.DisplayName);
      await this.storage.set(this.KEYS.AppleImageUrl, user.Apple.ProfileImageUrl);
    }

    // ////console.log('>>> SecureUserRepositoryService.set(): ' + JSON.stringify(user));
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  }

  async get(): Promise<UserApiUser> {
    return new Promise<UserApiUser>(async (resolve, reject) => {
      const id = await this.storage.get(this.KEYS.Id);
      const email = await this.storage.get(this.KEYS.Email);
      if (id && email) {
        const imageUrl = await this.storage.get(this.KEYS.ImageUrl);
        const duty = await this.storage.get(this.KEYS.Duty);
        const facebookDisplayName = await this.storage.get(this.KEYS.FacebookName);
        const facebookImageUrl = await this.storage.get(this.KEYS.FacebookImageUrl);
        const googleDisplayName = await this.storage.get(this.KEYS.GoogleName);
        const googleImageUrl = await this.storage.get(this.KEYS.GoogleImageUrl);
        const appleDisplayName = await this.storage.get(this.KEYS.AppleName);
        const appleImageUrl = await this.storage.get(this.KEYS.AppleImageUrl);
        let facebook: SocialNetwork = { DisplayName: facebookDisplayName, ProfileImageUrl: facebookImageUrl };
        let google: SocialNetwork = { DisplayName: googleDisplayName, ProfileImageUrl: googleImageUrl };
        let apple: SocialNetwork = { DisplayName: appleDisplayName, ProfileImageUrl: appleImageUrl };

        if (!facebook.DisplayName || !facebook.ProfileImageUrl) {
          facebook = null;
        }

        if (!google.DisplayName || !google.ProfileImageUrl) {
          google = null;
        }

        if (!apple.DisplayName || !apple.ProfileImageUrl) {
          apple = null;
        }

        const keys = await this.storage.keys();
        const af: AdditionalField[] = [];

        const user = {
          Id: id,
          Email: email,
          ImageUrl: imageUrl,
          Duty: duty,
          AdditionalFields: af,
          Facebook: facebook,
          GooglePlus: google,
          Apple: apple,
        };

        const whiteListedKeys: string[] = [];
        if (keys) {
          keys.forEach(item => {
            if (item.includes(this.KEYS.AdditionalPropertyPrefix)) {
              whiteListedKeys.push(item);
            }
          });
        }

        if (whiteListedKeys.length === 0) {
          // ////console.log('>>> SecureUserRepositoryService.get(): ' + JSON.stringify(user));
          this.refreshSection.next(true);
          resolve(user);
        } else {
          whiteListedKeys.forEach(async (key, index) => {
            // need to keep track of the index otherwise it will endup in a race condition issue
            // and the user returned not have additional fields
            const value = await this.storage.get(key);
            const newAF: AdditionalField = { Name: key.substring(this.KEYS.AdditionalPropertyPrefix.length), Value: value };
            af.push(newAF);

            if (whiteListedKeys.length - 1 === index) {
              // last item
              // ////console.log('>>> SecureUserRepositoryService.get(): ' + JSON.stringify(user));
              this.refreshSection.next(true);
              resolve(user);
            }
          });
        }
      } else {
        reject('Invalid user id or email');
      }
    });
  }

  async getAdditionalField(key: string): Promise<string | null> {
    return new Promise<string | null>(async (resolve, reject) => {
      this.storage.get(`${this.KEYS.AdditionalPropertyPrefix}${key}`).then(value => {
        resolve(value);
      }).catch(err => {
        reject(err);
      });
    });
  }

  async setLoginWithInHouse(): Promise<boolean> {
    await this.storage.set(this.KEYS.loginWith, this.LOGIN_WITH_IN_HOUSE);
    await this.storage.set(this.KEYS.loginWithName, 'InHouse');
    return new Promise<boolean>((resolve, reject) => {
      resolve(true);
    });
  }

  async setLoginWithFacebook(): Promise<boolean> {
    await this.storage.set(this.KEYS.loginWith, this.LOGIN_WITH_FACEBOOK);
    await this.storage.set(this.KEYS.loginWithName, 'Facebook');
    return new Promise<boolean>((resolve, reject) => {
      resolve(true);
    });
  }

  async setLoginWithGoogle(): Promise<boolean> {
    await this.storage.set(this.KEYS.loginWith, this.LOGIN_WITH_GOOGLE);
    await this.storage.set(this.KEYS.loginWithName, 'Google');
    return new Promise<boolean>((resolve, reject) => {
      resolve(true);
    });
  }

  async setLoginWithApple(): Promise<boolean> {
    await this.storage.set(this.KEYS.loginWith, this.LOGIN_WITH_APPLE);
    await this.storage.set(this.KEYS.loginWithName, 'Apple');
    return new Promise<boolean>((resolve, reject) => {
      resolve(true);
    });
  }

  async getLoginWith(): Promise<number> {
    return new Promise<number | null>(async (resolve, reject) => {
      this.storage.get(`${this.KEYS.loginWith}`).then(value => {
        resolve(value);
      }).catch(err => {
        reject(err);
      });
    });
  }

  async getEmail(): Promise<string | null> {
    return new Promise<string | null>(async (resolve, reject) => {
      this.storage.get(this.KEYS.Email).then(value => {
        resolve(value);
      }).catch(err => {
        reject(err);
      });
    });
  }

  async getImageUrl(): Promise<string | null> {
    return new Promise<string | null>(async (resolve, reject) => {
      this.getLoginWith().then(type => {
        if (this.LOGIN_WITH_FACEBOOK === type) {
          this.storage.get(`${this.KEYS.FacebookImageUrl}`).then(value => {
            resolve(value);
          }).catch(err => {
            resolve(null);
          });
        } else if (this.LOGIN_WITH_GOOGLE === type) {
          this.storage.get(`${this.KEYS.GoogleImageUrl}`).then(value => {
            resolve(value);
          }).catch(err => {
            resolve(null);
          });
        } else if (this.LOGIN_WITH_APPLE === type) {
          this.storage.get(`${this.KEYS.AppleImageUrl}`).then(value => {
            resolve(value);
          }).catch(err => {
            resolve(null);
          });
        } else if (this.LOGIN_WITH_IN_HOUSE === type) {
          this.storage.get(`${this.KEYS.ImageUrl}`).then(value => {
            resolve(value);
          }).catch(err => {
            resolve(null);
          });
        }
      }).catch(err => {
        reject(null);
      });
    });
  }

  async getImageUrl2(){
    const loginType = await this.storage.get(`${this.KEYS.loginWith}`);
    var image = null;
    switch (loginType) {
      case 0:
        image =  await this.storage.get(`${this.KEYS.ImageUrl}`)
        break;
      case 1:
        image =  await this.storage.get(`${this.KEYS.FacebookImageUrl}`)
        break;
      case 2:
        image =  await this.storage.get(`${this.KEYS.GoogleImageUrl}`)
        break;
      default:
        break;
    }
    return image;
  }

  async getDisplayUserName(defaultKey: string): Promise<string | null> {
    return new Promise<string | null>(async (resolve, reject) => {
      this.getLoginWith().then(type => {
        if (this.LOGIN_WITH_FACEBOOK === type) {
          this.storage.get(`${this.KEYS.FacebookName}`).then(value => {
            resolve(value);
          }).catch(err => {
            resolve(null);
          });
        } else if (this.LOGIN_WITH_GOOGLE === type) {
          this.storage.get(`${this.KEYS.GoogleName}`).then(value => {
            resolve(value);
          }).catch(err => {
            resolve(null);
          });
        } else if (this.LOGIN_WITH_APPLE === type) {
          this.storage.get(`${this.KEYS.AppleName}`).then(value => {
            resolve(value);
          }).catch(err => {
            resolve(null);
          });
        } else if (this.LOGIN_WITH_IN_HOUSE === type) {
          this.storage.get(`${this.KEYS.AdditionalPropertyPrefix}${defaultKey}`).then(value => {
            resolve(value);
          }).catch(err => {
            resolve(null);
          });
        }
      }).catch(err => {
        reject(null);
      });
    });
  }

}
