import { Component } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ServiceWorkerUpdateService } from './core/services/service-worker-update.service';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { NetworkService } from "./services/network.service";
import { TokenControllerService } from './messages-sdk/services/token-controller.service';
import { TranslateService } from '@ngx-translate/core';
import { CodebaseTranslateService } from './services/codebase-translate.service';
import { ChannelsClientService } from './messages-sdk/services/channels-client.service';
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';
import { environment } from '@env/environment';
declare function require(name:string);


@Component({
  selector: 'app-root',
  templateUrl: require('template/' + environment.template + '/html/app.component.html'),
})
export class AppComponent {
  public showSplash: boolean = true;
  public splashImage: string;
  public environment = environment;
  bodyStyles = document.body.style;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private update: ServiceWorkerUpdateService,
    private router: Router,
    private storage: Storage,
    private push: Push,
    private toastController: ToastController,
    private tokenController: TokenControllerService,
    private networkService: NetworkService,
    private translate: TranslateService,
    private channelService: ChannelsClientService,
    private codebaseTranslateService: CodebaseTranslateService
  ) {
    this.initializeApp();
    // storage.get('language').then(lang => {
    // })
  }

  async initializeApp() {
    this.bodyStyles.setProperty('--image', `url("/themes/${environment.theme.name}/assets/login/logIn_background.png")`)
    this.bodyStyles.setProperty('--logo', `url("/themes/${environment.theme.name}/assets/login/logo.png")`)
    this.splashImage = `../../../themes/${environment.theme.name}/assets/login/logIn_background.png`;
      // this.codebaseTranslateService.languageChange('eng')
    // this.codebaseTranslateService.initLanguage();
    if (this.networkService.getCurrentNetworkStatus() < 1){
      const toast = await this.toastController.create({
        message: 'This app is offline',
        duration: 2000,
        buttons: [
          {
            text: 'Close',
            handler: (() => {

            })
          }
        ]
      });
      toast.present();
    }
    // this.codebaseTranslateService.languageChange("eng");
    var timer=0;
    var interval = setInterval(() => {
    timer++;
    if (timer<7) {

    }
    else {
      clearInterval(interval);
      this.showSplash = false;
      ////console.log('Done');
    }
    }, 1000);



    if (this.platform.is("android")){
      var token = await this.storage.get('pushToken');
      if (!token)
        this.requestPermission();
    }else if (this.platform.is("ios")){
      var token = await this.storage.get('pushToken');
      if (!token)
      this.requestNotificationPermission
    }
  }

  requestNotificationPermission() {
    const options: PushOptions = {
      android: {},
      ios: {
        alert: 'true',
        badge: true,
        sound: 'false',
      },
    };

    const pushObject: PushObject = this.push.init(options);

    pushObject.on('registration').subscribe(async (registration: any) => {
      //console.log('Device registered', registration);
      await this.storage.set('pushToken', registration);
      await this.tokenController.registerDeviceTokenIos(registration);
      await this.channelService.postSubscribeToChannels(['GlobalNotifications'], registration);
      // Aquí puedes enviar el token de registro a tu servidor para enviar notificaciones.
    });

    pushObject.on('notification').subscribe((notification: any) => {
      //console.log('Received a notification', notification);
      // Aquí puedes manejar la notificación cuando se recibe.
    });

    pushObject.on('error').subscribe((error: any) => {
      console.error('Error with Push plugin', error);
    });
  }

  requestPermission() {
    this.tokenController.requestPermission().subscribe(
      async token => {
        await this.storage.set('pushToken', token);
        await this.tokenController.registerDeviceToken(token);
        await this.channelService.postSubscribeToChannels(['GlobalNotifications'], token);
        //console.log(token)
      },
      async (err) => {
        //console.log(err)
      }
    );
  }
}
