import { AgmFitBounds, AgmMap } from '@agm/core';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Listing } from '@app/core/data/Listing';
import { environment } from '@env/environment';
import { Events, IonInfiniteScroll, IonSlides, ModalController } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { ListDetailsPage, LocationEntity } from '../../list-details/list-details.page';
import { timer } from 'rxjs';
import { delay } from 'rxjs/operators';
import _ from 'underscore';
import { Advertisement } from '@app/core/data/Advertisement';
declare function require(name:string);


@Component({
  selector: 'app-listings-map',
  templateUrl: require('template/' + environment.template + '/html/listings-map.component.html'),
  styles: [require('template/' + environment.template + '/styles/listings-map.component.scss')]
})

export class ListingsMapComponent implements OnInit {

  public environment = environment;
   @Input() listings: Listing[];
  public filteredArray: Listing[];

  latitude: number;
  longitude: number;
  userLocation!: LocationEntity;
  zoom = 11;
  selectedItem!: Listing;
  slideImages: string [];
  loadCarousel = true;
  previousIndex: number;
  windowH: number;
  @Input() isLoading: Boolean;
  @Input() advertisements:Advertisement[] = [];
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  public mapStyles = [
    {
    "featureType": "poi",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
      ]
    }
  ];


  @ViewChild(AgmMap) agmMap;
  @ViewChild('map') map: AgmMap;
  @ViewChild(AgmFitBounds) fitBounds: AgmFitBounds;
  @ViewChild('slideWithNav2') slideWithNav2: IonSlides;
  items = [];
  count = 0;


  slideOptsTwo = {
    initialSlide: 0,
    slidesPerView: 1,
    spaceBetween: 20
  };

  constructor(
    private router: Router,
    private geolocation: Geolocation,
    public events: Events,
    private route: ActivatedRoute,
    private modalCtrlr: ModalController,
    private mapsAPILoader: MapsAPILoader
  ) {
    this.latitude = environment.cms.main_coordinates.latitude;
    this.longitude = environment.cms.main_coordinates.longitude;

    this.events.subscribe('testevent', (data) => {
      if (data.listings) {
        this.filteredArray = data.listings.filter(function (itm) {
          return itm.Company;
        });
        // this.listings = filteredArray;
        //console.log(this.filteredArray);
        this.items = this.filteredArray.slice(0,150);
        this.count = 150;
        this.previousIndex = 0;
      }
      this.isLoading=false;
      this.slideWithNav2.update();
      this.slideWithNav2.slideTo(0);
    });

  }

  ngOnInit() {

    this.route.queryParams.subscribe((params) => {

      this.events.subscribe('testevent', (data) => {
        console.log("listings-list-tnt:testevent");
        if (data.listings) {
          this.filteredArray = data.listings.filter(function (itm) {
            return itm.Company;
          });
          // this.listings = filteredArray;
          //console.log(this.filteredArray);
          this.items = this.filteredArray.slice(0,150);
          this.count = 150;
        }
        this.isLoading=false;
        this.slideWithNav2.update();
        this.slideWithNav2.slideTo(0);
      });
    })
    ////console.log("on init")
    if (this.listings && this.listings.length > 0){

      this.filteredArray = _.filter(this.listings, function(item: any): any {
        if ('Latitude' in item && item.Latitude !== null &&
        'Longitude' in item && item.Longitude !== null
        && item.Address1 != ''){
          return item
        }
      });
      this.listings = this.filteredArray;
      ////console.log(this.listings.length)
      // this.trackLocation();

      this.windowH = window.innerHeight-350;
      this.latitude = this.filteredArray[0].Latitude;
      this.longitude = this.filteredArray[0].Longitude;
      this.filteredArray[0].IconWidth = 45;
      this.filteredArray[0].IconHeight = 60;
      this.filteredArray[0].zIndex = 1000;
      this.previousIndex = 0;
    }
    // ////console.log(this.listings && this.listings.length)
  }

  trackLocation() {
    this.geolocation.getCurrentPosition().then((resp) => {
      //this.isLocationAvailable = true;
      if (this.filteredArray) {
        // compute distance and bind it to each listing
        this.userLocation = { latitude: resp.coords.latitude, longitude: resp.coords.longitude };
        if (!this.latitude) {
          this.latitude = resp.coords.latitude;
          this.longitude = resp.coords.longitude;
        }
        this.setDistance();
      } else {
        console.error('listings not available yet');
      }
    }).catch((error) => {
      console.error('Error getting location', error);
      //this.isLocationAvailable = false;
    });
  }
  metersToMi(meters: number): number {
    return meters * 0.000621371;
  }

  setDistance() {
    this.mapsAPILoader.load().then(() => {

      var location = new google.maps.LatLng(this.userLocation.latitude, this.userLocation.longitude);
      this.filteredArray.forEach(item => {
        if (item.Latitude && item.Longitude) {
          const listingLocation = new google.maps.LatLng(+item.Latitude, +item.Longitude);
          const distanceInM = google.maps.geometry.spherical.computeDistanceBetween(location, listingLocation);
          item.Distance = parseFloat(this.metersToMi((Math.round(distanceInM * 100) / 100)).toFixed(2));
        }
      });
      this.filteredArray.sort(function (a, b) {
        return a.Distance - b.Distance;
      });
      // if (this.listings[0].Distance > 150) {

      // }
    }
    );

  }


  selectMarker(event: any, item: Listing, i) {
    this.slideWithNav2.slideTo(i);
    //this.zoom = 13; // can set a default zoom if needed
  }

  onOpenListingClick(item: Listing) {
    // this.showLoginPopup(item.ListingID)
    this.router.navigate(['/tabs/listings', item.ListingID]);
  }

  //Method called when slide is changed by drag or navigation
  SlideDidChange(object, slideView) {
    this.filteredArray[this.previousIndex].IconWidth = 22;
    this.filteredArray[this.previousIndex].IconHeight = 30;
    this.filteredArray[this.previousIndex].zIndex = 1;

    this.slideWithNav2.getActiveIndex().then(index => {
      this.latitude = this.filteredArray[index].Latitude;
      this.longitude = this.filteredArray[index].Longitude;

      // this.filteredArray[index].Icon = 'themes/{{environment.theme.name}}/assets/icons/pin_map.svg'
      this.filteredArray[index].IconWidth = 45;
      this.filteredArray[index].IconHeight = 60;
      this.filteredArray[index].zIndex = 1000;
      this.previousIndex = index
      //console.log(index+1, this.items.length)
      if (index+1 == this.items.length){
        this.loadData(this.infiniteScroll)
      }

   });
  }

  loadData(event) {
    //console.log("loadData")
    setTimeout(() => {
      //console.log('Done');
      if (this.count < this.filteredArray.length) {
        this.addMoreItems();
      }
      // event.target.complete();
    }, 500);
  }


  addMoreItems() {
    for (let i = 0; i < 150; i++) {
      if (this.count < this.filteredArray.length) {
        this.items.push(this.filteredArray[this.count]);
        this.count += 1;
      }
    }
  }
}
