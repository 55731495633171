import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { AlertService } from '@app/shared/services/alert.service';
import { ItineraryItem } from '@app/itinerary-builder-sdk/interfaces/itinerary-item';
import moment from 'moment';
import { environment } from '@env/environment';
declare function require(name:string);

@Component({
  selector: 'app-stop-cell',
  templateUrl: require('template/' + environment.template + '/html/stop-cell.component.html'),
  styles: [require('template/' + environment.template + '/styles/stop-cell.component.scss')]
})
export class StopCellComponent implements OnInit {

  public environment = environment;
  public editTimeForm!: FormGroup;
  public editMode = false;
  public visibleTimeRange: string;
  @Output() stopChanged = new EventEmitter<{
    action: string,
    stopId: number,
    listingId?: number,
    eventId?: number,
    startTime?: string,
    endTime?: string
  }>();
  @Input() public dateString!: string;
  @Input() public stops!: ItineraryItem[];
  @Input() public stop!: ItineraryItem;
  @Input() public stopIndex!: number;
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    ////console.log(this.stop)
    let startTimeValue = null;
    let endTimeValue = null;
    if (this.stop.StartTime !== undefined && this.stop.StartTime !== 'undefined') {
      startTimeValue = this.stop.StartTime;
    } else {
      console.error(`Invalid start time: [${this.stop.StartTime}]`);
    }
    if (this.stop.EndTime !== undefined && this.stop.EndTime !== 'undefined') {
      endTimeValue = this.stop.EndTime;
    } else {
      console.error(`Invalid end time: [${this.stop.EndTime}]`);
    }
    this.editTimeForm = this.fb.group({
      startTime: [startTimeValue, [Validators.required]],
      endTime: [endTimeValue, [Validators.required]],
    });
    this.visibleTimeRange = this.convertDateInterval(this.stop.StartTime, this.stop.EndTime);
  }

  async onSubmit() {
    const startMoment = moment(this.editTimeForm.controls.startTime.value, 'hh:mm:ss');
    const endMoment = moment(this.editTimeForm.controls.endTime.value, 'hh:mm:ss');
    const newStartTime = startMoment.toDate();
    const newEndTime = endMoment.toDate();
    newStartTime.setSeconds(0, 0);
    newEndTime.setSeconds(0, 0);
    this.stop.StartTime = newStartTime.toISOString();
    this.stop.EndTime = newEndTime.toISOString();
    this.visibleTimeRange = startMoment.format('h:mm a') + ' - ' + endMoment.format('h:mm a');
    this.stops[this.stopIndex] = this.stop;
    this.stopChanged.emit({
      action: 'edit', stopId: this.stop.Id, listingId: this.stop.ListingId, eventId: this.stop.EventId,
      startTime: moment(this.stop.StartTime).format('HH:mm:ss'), endTime: moment(this.stop.EndTime).format('HH:mm:ss')
    });
    this.editMode = false;
    return true;
  }

  onSetTimesCancel() {
    let startTimeValue = null;
    let endTimeValue = null;
    if (this.stop.StartTime !== undefined && this.stop.StartTime !== 'undefined') {
      startTimeValue = this.stop.StartTime;
    }
    if (this.stop.EndTime !== undefined && this.stop.EndTime !== 'undefined') {
      endTimeValue = this.stop.EndTime;
    }
    this.editTimeForm.controls.startTime.setValue(startTimeValue);
    this.editTimeForm.controls.endTime.setValue(endTimeValue);
    this.editMode = false;
  }

  onStopDeleteTap() {
    this.alertService.presentInfoAlert('Do you want to delete this item?', null, [{
      text: 'Yes', handler: (() => {
        this.stopChanged.emit({ action: 'delete', stopId: this.stop.Id });
      }).bind(this)
    },
    { text: 'Cancel' }
    ]);
  }

  onEditTimeTap() {
    this.editMode = true;
  }

  onDrivingDirectionsTap() {
    window.open(`https://www.google.com/maps/search/?api=1&query=${this.stop.Latitude},${this.stop.Longitude}`, '_blank');
  }

  public convertDateInterval(startTime?: string, endTime?: string) {
    const startDateTime = this.convertDateToTime(startTime);
    const endDateTime = this.convertDateToTime(endTime);
    if (startDateTime === '' && endDateTime === '') {
      return '';
    }
//    ////console.log(`startTime[${startTime}], endTime[${endTime}], startDateTime[${startDateTime}], endDateTime[${endDateTime}]`);
    return `${startDateTime} - ${endDateTime}`;
  }

  public convertDateToTime(timeString?: string) {
    if (!timeString || timeString === 'undefined' || timeString === undefined) {
      return '';
    }

    const parsed = moment(timeString, 'hh:mm:ss');
    if (!parsed.isValid()) {
      ////console.log(`>>> Invalid time[${timeString}]`);
    }
    return parsed.format('h:mm a');
  }
}
