import { AdStyle } from '@app/core/data/enums/AdStyle';
import { Advertisement } from '../../core/data/Advertisement';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { environment } from '@env/environment';
declare function require(name:string);

@Component({
  selector: 'app-advertisements',
  templateUrl: require('template/' + environment.template + '/html/advertisements.component.html'),
  styles: [require('template/' + environment.template + '/styles/advertisements.component.scss')]
})

export class AdvertisementsComponent implements OnInit {

  public environment = environment;  @Input() public Advertisements: Advertisement[] = []
  @Input() public Style: AdStyle;

  @ViewChild('adSlider') adSlider: IonSlides;
   activeAdvertisements: Advertisement[] = [];
   isVisible: boolean = false;

   sliderAd: any =
   {
     isBeginningSlide: true,
     isEndSlide: false,
     slidesItems: []
   };

   slideOpts = {
     initialSlide: 0,
     slidesPerView: 1,
     spaceBetween: 20,
     speed: 400,
     autoplay: true
   };
  constructor() {
   }

  ngOnInit() {

    console.log(this.Advertisements);

    if(this.Advertisements != undefined && this.Advertisements != null && this.Advertisements.length > 0)
      this.activeAdvertisements= this.Advertisements.filter(x => x.AdType === this.Style);
    console.log(this.activeAdvertisements);

    if(this.activeAdvertisements != undefined && this.activeAdvertisements != null && this.activeAdvertisements.length > 0)
    {

      console.log(this.activeAdvertisements);

      setTimeout(()=> {
        this.isVisible = true;
        this.adSlider.update();
        this.adSlider.slideTo(0);
      }, 3000);
    }
  }

  adClose() {
    this.isVisible=false;
  }

  SlideDidChange(object, slideView) {
    this.checkIfNavDisabled(object, slideView);
  }

  checkIfNavDisabled(object, slideView) {
    this.checkisBeginning(object, slideView);
    this.checkisEnd(object, slideView);
  }

  checkisBeginning(object, slideView) {
    slideView.isBeginning().then((istrue) => {
      object.isBeginningSlide = istrue;
    });
  }
  checkisEnd(object, slideView) {
    slideView.isEnd().then((istrue) => {
      object.isEndSlide = istrue;
    });
  }

}
