import { settings as settings } from '../environments/settings';
export const environment = {
  ...settings,
  template: 'pwa-1.0',
  theme: {
    name: 'connecticut',
    appName: 'Connecticut',
    productionHostName: 'connecticut-trueomni.web.app',
    stagingHostName: 'staging-connecticut.web.app',
  },
  cms: {
    domainId: 1047,
    accountId: 1047,
    channelId: 1223,
    deviceId: 1048,
    formId: 158,
    postalCode: '06103',
    bandwangosPageId: 3388,
    visitorCenterId: 18004,
    main_coordinates: {
      latitude: settings.site.latitude,
      longitude: settings.site.longitude,
    },
    navigation: {
      avatar_enabled: false,
      homePageId: 4424,
      itemsPerRowCategory: 2,
      footerPageId: 0
    }
  },
  regions:{
    enabled: true,
    regionsPageId: 6977
  },
  coreAuthLib: {
    base_url: 'https://usersapi.mobimanage.com',
    client_secret: '7riKwSucH0iDw5Z5uM87ag',
  },
  firebase: {
    apiKey: "AIzaSyCs-n8i0vbInOzdDuluiYYOmCwXo_9qn7w",
    authDomain: "visit-connecticut.firebaseapp.com",
    projectId: "visit-connecticut",
    storageBucket: "visit-connecticut.appspot.com",
    messagingSenderId: "925676306467",
    appId: "1:925676306467:web:e8b7b76682b73707b4b196",
    measurementId: "G-DN5S70DGCL"
  },
  messagesSDK: {
    client_id: 1630,
    client_secret: '-ijGvmnQ70So_2EFdZoRdA',
    base_url: 'https://pushapi.mobimanage.com'
  },
  socialWall: {
    client_id: '11047',
    client_secret: 'rQXHylh89jLZA74bfgl44O92X=11047=',
    url: 'https://wall.crowdriff.com/live/24206c2e-3bba-407a-b649-8abd93f1b40b'
  },
  googleMaps: 'AIzaSyAb0SnmmZsewjJYWVDY5U3D8-oo1zKh-Jg',
  google: {
    client_id: '925676306467-fsrfrohc3nt3thvjrb8f1t340h0absad.apps.googleusercontent.com',
  },
  facebook: {
    app_id: '717309220563234',
  },
  batuta: {
    api_key: 'e8e98196453d6f0bd4ffd6553d56a829',
    base_url: 'http://battuta.medunes.net/api/'
  },
  tokenUniversal: {
    token: "DWuAh7qPqHqyp8la7Os4568OuHLu6dbpyvo11ZtsIfPvAnQgZOEPQk9Ssqdlb42VqVI"
  },
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
