import { environment as custom } from "../themes/Connecticut/environments/environment"
export const environment = {
  ...custom,
  template: 'pwa-1.0',
  theme: {
    name: 'Connecticut'
  },
  production: false,
  itineraryBuilderSDK: {
    base_url: 'https://itinerarybuilderapi.mobimanage.com'
  },
  scavengerHunt: {
    base_url: 'https://scavengerhuntapi.mobimanage.com'
  },
  reviewsAPI: {
    base_url: 'https://reviewsapi.mobimanage.com'
  },
  googleMaps: 'AIzaSyAb0SnmmZsewjJYWVDY5U3D8-oo1zKh-Jg',
  cookies: {
    defaultExpiration: 182,
    authCookieName: 'auth'
  },
  pwaAPI:{
    base_url:"https://webservice2.mobimanage.com/json.aspx"
  },
  paypal: {
    enabled: true,
    currency: 'USD',
    clientId: 'sb',
    category: 'DIGITAL_GOODS'
  },
  cloudbedsApi: {
    url: 'https://cloudbeds-api.azurewebsites.net/'
  },
  tokenUniversal: {
    token: "DWuAh7qPqHqyp8la7Os4568OuHLu6dbpyvo11ZtsIfPvAnQgZOEPQk9Ssqdlb42VqVI"
  },
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
