import { AlertButton } from '@ionic/core';
import { AlertController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { environment } from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private alert!: HTMLIonAlertElement;

  constructor(private alertCtrl: AlertController) { }

  async presentInfoAlert(message: string, header?: string, buttons?: AlertButton[]) {

    this.alert = await this.alertCtrl.create({
      header,
      message,
      buttons
    });

    return this.alert.present();
  }

  async dismiss() {
    return this.alert.dismiss();
  }
  public static log(message: any, optionalParams?: any[]) {
    if (!environment.production || environment.debug) {
      //console.log(message, optionalParams);
    }
  }
  public static warn(message: any, optionalParams?: any[]) {
    if (!environment.production || environment.debug) {
      console.warn(message, optionalParams);
    }
  }
  public static error(message: any, optionalParams?: any[]) {
    if (!environment.production || environment.debug) {
      console.error(message, optionalParams);
    }
  }

}
