import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LikedListingsComponent } from '@app/codebase/itineraries/liked-listings/liked-listings.component';
import { CMSEvent } from '@app/core/data/CMSEvent';
import { Listing } from '@app/core/data/Listing';
import { CMSDataControllerService } from '@app/core/data/controller/cmsdata-controller.service';
import { FavoritesService } from '@app/core/services/favorites.service';
import { Itinerary } from '@app/itinerary-builder-sdk/interfaces/itinerary';
import { ItineraryDay } from '@app/itinerary-builder-sdk/interfaces/itinerary-day';
import { ItineraryItem } from '@app/itinerary-builder-sdk/interfaces/itinerary-item';
import { ItineraryBuilderDayClientService } from '@app/itinerary-builder-sdk/services/itinerary-builder-day-client.service';
import { ItineraryBuilderItineraryClientService } from '@app/itinerary-builder-sdk/services/itinerary-builder-itinerary-client.service';
import { LoadingService } from '@app/shared/services/loading.service';
import { environment } from '@env/environment';
import { ModalController } from '@ionic/angular';
import { FavoriteListingsComponent } from '../favorite-listings/favorite-listings.component';
declare function require(name:string);

@Component({
  selector: 'app-create-itinerary',
  templateUrl: require('template/' + environment.template + '/html/create-itinerary.component.html'),
  styles: [require('template/' + environment.template + '/styles/create-itinerary.component.scss')]
})
export class CreateItineraryComponent implements OnInit {

  public environment = environment;  @Input() title: string;
  @Input() public day: ItineraryDay;
  public stops!: ItineraryItem[];
  public itinerary?: Itinerary;
  @ViewChild('datePicker') datePicker: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private loading: LoadingService,
    private itineraryBuilderItineraryClient: ItineraryBuilderItineraryClientService,
    private itineraryBuilderDayClientService: ItineraryBuilderDayClientService,
    private modalController: ModalController,
    private dataController: CMSDataControllerService,
    private favoritesService: FavoritesService,
  ) {
    this.title = this.route.snapshot.queryParams.t;
  }

  ngOnInit() {
    this.loadItinerary();
  }

  goBack(){
    window.history.back()
  }

  public async onNewDayDateChanged(date: any) {
    await this.loading.presentLoading();
    const year: string = date.year.text;
    const month: string = date.month.value < 10 ? '0' + date.month.value.toString(): date.month.value.toString();
    const day: string = date.day.text;
    const dateString = year + '/' + month + '/' + day;
    const dateObject = new Date(dateString);
    dateObject.setHours(0, 0, 0, 0);
    const isoString = dateObject.toISOString();
    await this.addNewDay(isoString);
    this.datePicker = null;
    return this.loading.dismiss();
  }

  async addNewDay(isoDate: string) {
    const itineraryId = this.route.snapshot.params.id;
    await this.itineraryBuilderDayClientService.addDay(parseInt(itineraryId, 10), isoDate);
    await this.loadItinerary();
  }

  async onAddElementButtonClick() {
    const modal = await this.modalController.create({
      component: FavoriteListingsComponent,
      componentProps: {
        // itineraryId: this.route.snapshot.params.id,
        // dayId: this.day.Id,
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    ////console.log(data)
    if (data && data.updated && data.itineraryItem) {
      this.stops.push(data.itineraryItem);
    } else {
      ////console.log('>>> Nothing changed');
    }
  }

  private createDefaultDay() {
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    const isoString = todayDate.toISOString();
    this.addNewDay(isoString);
  }

  async loadItinerary() {
    const itineraries = await this.itineraryBuilderItineraryClient.getItineraries();
    const itineraryId = this.route.snapshot.queryParams.itineraryId;
    const itineraryIdNumber = parseInt(itineraryId, 10);
    const selectedItinerary = itineraries.filter(anItinerary => anItinerary.Id === itineraryIdNumber).pop();
    if (selectedItinerary !== null && selectedItinerary !== undefined && selectedItinerary.ItineraryDays) {
      this.itinerary = selectedItinerary;
      if (this.itinerary.ItineraryDays.length === 0) {
        this.createDefaultDay();
      } else {
        // this itinerary has more than one day available
        // so let's pull the data of each listing/event

        let favEvents: CMSEvent[];
        let favListings: Listing[];
        try {
          favListings = await this.favoritesService.getFavoritesListings();
          favEvents = await this.favoritesService.getFavoritesEvents();
          ////console.log(`>>> Favorite listings: ${favListings.length}, Favorite events: ${favEvents.length}`);
        } catch (error) {
          console.error(error);
          favListings = [];
          favEvents = [];
        }

        this.itinerary.ItineraryDays.forEach(item => {
          item.ItineraryItems.forEach(async picked => {
            if (picked.ListingId) {
              // pull listing data and bind it to the picked element
              // Optimization: no need to pull if it's already marked as favorite(already cached)
              const cachedItem = favListings.find(cached => cached.ListingID === picked.ListingId);
              if (cachedItem) {
                // favorite item so we already have its data ready and no need to pull anything
                ////console.log(`>>> Favorite listing[${picked.ListingId}], no need to pull`);
                picked.Title = cachedItem.Company;
                picked.ImageUrl = cachedItem.Image_List && cachedItem.Image_List.split('|').length > 0 ?
                cachedItem.Image_List.split('|')[0] : null;
                picked.Latitude = cachedItem.Latitude;
                picked.Longitude = cachedItem.Longitude;
              } else {
                ////console.log(`>>> Pulling listing[${picked.ListingId}]`);
                try {
                  const pulledItems = await this.dataController.getListingById(
                  { domainId: environment.cms.domainId, dataSyndication: false, listingId: picked.ListingId }).toPromise();
                  if (pulledItems && pulledItems.length > 0) {
                    ////console.log(pulledItems[0].Image_List);
                    picked.Title = pulledItems[0].Company;
                    picked.ImageUrl = pulledItems[0].Image_List && pulledItems[0].Image_List.split('|').length > 0 ?
                    pulledItems[0].Image_List.split('|')[0] : null;
                    picked.Latitude = pulledItems[0].Latitude;
                    picked.Longitude = pulledItems[0].Longitude;
                    this.favoritesService.favoriteListing(pulledItems[0]);
                  } else {
                    // can't pull data, let's ignore it and keep going
                    console.warn(`Can not pull listing: ${picked.ListingId}`);
                  }
                } catch (error) {
                  // can't pull data, let's ignore it and keep going
                  console.error(error);
                }
              }
            } else if (picked.EventId) {
              // pull event data and bind it to the picked element
              // Optimization: no need to pull if it's already marked as favorite(already cached)
              const cachedItem = favEvents.find(cached => cached.EventID === picked.EventId);
              if (cachedItem) {
                // favorite item so we already have its data ready and no need to pull anything
                ////console.log(`>>> Favorite event[${picked.EventId}], no need to pull`);
                picked.Title = cachedItem.Title;
                picked.ImageUrl = cachedItem.Image_List && cachedItem.Image_List.split('|').length > 0 ?
                cachedItem.Image_List.split('|')[0] : null;
                picked.Latitude = cachedItem.Latitude;
                picked.Longitude = cachedItem.Longitude;
              } else {
                ////console.log(`>>> Pulling event[${picked.EventId}]`);
                try {
                  const pulledItems = await this.dataController.getEventById(
                  { domainId: environment.cms.domainId, dataSyndication: false, eventId: picked.EventId }).toPromise();
                  if (pulledItems && pulledItems.length > 0) {
                    picked.Title = pulledItems[0].Title;
                    picked.ImageUrl = pulledItems[0].Image_List && pulledItems[0].Image_List.split('|').length > 0 ?
                    pulledItems[0].Image_List.split('|')[0] : null;
                    picked.Latitude = pulledItems[0].Latitude;
                    picked.Longitude = pulledItems[0].Longitude;
                    this.favoritesService.favoriteEvent(pulledItems[0]);
                  } else {
                    // can't pull data, let's ignore it and keep going
                    console.warn(`Can not pull event: ${picked.EventId}`);
                  }
                } catch (error) {
                  // can't pull data, let's ignore it and keep going
                  console.error(error);
                }
              }
            }
          });
        });
      }

    }
  }
}
