export const settings = {
  languages: [
    { label: "English", value: 'en' },
    { label: 'Español', value: 'es' },
    { label: 'Français', value: 'fr' },
    { label: 'Deutsch', value: 'de' },
    { label: '中文', value: 'sc' },
    { label: 'हिन्दी', value: 'hi' }
  ],
  site: {
    name: 'Connecticut Office of Tourism',
    address: '450 Columbus Blvd., Suite 5, Hartford, CT 06103',
    phone: '8882884748',
    map: 'https://maps.app.goo.gl/ZsBFKQbcxU5f4qyR6',
    latitude: 41.7675462,
    longitude: -72.6689806,
    website:'https://ctvisit.com/',
    privacyLink:'https://ctvisit.com/privacy-policy',
    twitter:'https://twitter.com/CTvisit',
    facebook: 'https://www.facebook.com/VisitConnecticut',
    pinterest:'https://www.pinterest.com/visitct',
    instagram:'https://www.instagram.com/ctvisit',
    youtube:'',
    splashImage: '../themes/Connecticut/assets/splashscreens/bg_img.png',
    homeEvents: false
  },
  debug: true

};
