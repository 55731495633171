import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { AlertService } from '@app/shared/services/alert.service';
import { Itinerary } from '@app/itinerary-builder-sdk/interfaces/itinerary';
import { ItineraryBuilderDayClientService } from '@app/itinerary-builder-sdk/services/itinerary-builder-day-client.service';
import { ItineraryBuilderItemClientService } from '@app/itinerary-builder-sdk/services/itinerary-builder-item-client.service';
import { ItineraryBuilderItineraryClientService } from '@app/itinerary-builder-sdk/services/itinerary-builder-itinerary-client.service';
import { LoadingService } from '@app/shared/services/loading.service';
import { CMSDataControllerService } from '@app/core/data/controller/cmsdata-controller.service';
import { FavoritesService } from '@app/core/services/favorites.service';
import { CMSEvent } from '@app/core/data/CMSEvent';
import { Listing } from '@app/core/data/Listing';
import { environment } from '@env/environment';
import { UsersApiService } from '@app/core/users-api/users-api.service';
import moment from 'moment';
declare function require(name:string);

@Component({
  selector: 'app-itinerary-details',
  templateUrl: require('template/' + environment.template + '/html/itinerary-details.component.html'),
  styles: [require('template/' + environment.template + '/styles/itinerary-details.component.scss')]
})
export class ItineraryDetailsComponent implements OnInit {

  public environment = environment;
  public canEditItinerary = true;
  public events = "";
  public itinerary?: Itinerary;
  @ViewChild('datePicker') datePicker: ElementRef;
  customPickerOptions: any;
  public today = moment().format('YYYY-MM-DD');
  public limit = moment().add(5,'year').format('YYYY-MM-DD');

  constructor(
    public route: ActivatedRoute,
    private usersApi: UsersApiService,
    private itineraryBuilderItineraryClient: ItineraryBuilderItineraryClientService,
    private itineraryBuilderDayClientService: ItineraryBuilderDayClientService,
    private itineraryBuilderStopClientService: ItineraryBuilderItemClientService,
    private dataController: CMSDataControllerService,
    private favoritesService: FavoritesService,
    private loading: LoadingService,
    private alertService: AlertService,
    private router: Router
  ) { }

  async ngOnInit() {
    ////console.log(this.today,this.limit)
    await this.loading.presentLoading();
    this.customPickerOptions = {
      buttons: [{
        text: 'Cancel',
        role: 'cancel',
      }, {
        text: 'Done',
        handler: (data) => {
          this.onNewDayDateChanged(data);
        }
      }]
    };
    const type = this.route.snapshot.queryParams.type;
    this.canEditItinerary = type !== 'presetItineraries';
    await this.loadItinerary();
    return this.loading.dismiss();
  }

  goBack(){
    window.history.back()
  }

  async loadItinerary() {
    const itineraries = await this.itineraryBuilderItineraryClient.getItineraries();
    const itineraryId = this.route.snapshot.params.id;
    const itineraryIdNumber = parseInt(itineraryId, 10);
    const selectedItinerary = itineraries.filter(anItinerary => anItinerary.Id === itineraryIdNumber).pop();
    if (selectedItinerary !== null && selectedItinerary !== undefined && selectedItinerary.ItineraryDays) {
      this.itinerary = selectedItinerary;
      if (this.itinerary.ItineraryDays.length === 0) {
        this.createDefaultDay();
      } else {
        // this itinerary has more than one day available
        // so let's pull the data of each listing/event

        let favEvents: CMSEvent[];
        let favListings: Listing[];
        try {
          favListings = await this.favoritesService.getFavoritesListings();
          favEvents = await this.favoritesService.getFavoritesEvents();
          ////console.log(`>>> Favorite listings: ${favListings.length}, Favorite events: ${favEvents.length}`);
        } catch (error) {
          console.error(error);
          favListings = [];
          favEvents = [];
        }

        this.itinerary.ItineraryDays.forEach(item => {
          item.ItineraryItems.forEach(async picked => {
            if (picked.ListingId) {
              // pull listing data and bind it to the picked element
              // Optimization: no need to pull if it's already marked as favorite(already cached)
              const cachedItem = favListings.find(cached => cached.ListingID === picked.ListingId);
              if (cachedItem) {
                // favorite item so we already have its data ready and no need to pull anything
                ////console.log(`>>> Favorite listing[${picked.ListingId}], no need to pull`);
                picked.Title = cachedItem.Company;
                picked.ImageUrl = cachedItem.Image_List && cachedItem.Image_List.split('|').length > 0 ?
                cachedItem.Image_List.split('|')[0] : null;
                picked.Latitude = cachedItem.Latitude;
                picked.Longitude = cachedItem.Longitude;
              } else {
                ////console.log(`>>> Pulling listing[${picked.ListingId}]`);
                try {
                  const pulledItems = await this.dataController.getListingById(
                  { domainId: environment.cms.domainId, dataSyndication: false, listingId: picked.ListingId }).toPromise();
                  if (pulledItems && pulledItems.length > 0) {
                    ////console.log(pulledItems[0].Image_List);
                    picked.Title = pulledItems[0].Company;
                    picked.ImageUrl = pulledItems[0].Image_List && pulledItems[0].Image_List.split('|').length > 0 ?
                    pulledItems[0].Image_List.split('|')[0] : null;
                    picked.Latitude = pulledItems[0].Latitude;
                    picked.Longitude = pulledItems[0].Longitude;
                    this.favoritesService.favoriteListing(pulledItems[0]);
                  } else {
                    // can't pull data, let's ignore it and keep going
                    console.warn(`Can not pull listing: ${picked.ListingId}`);
                  }
                } catch (error) {
                  // can't pull data, let's ignore it and keep going
                  console.error(error);
                }
              }
            } else if (picked.EventId) {
              // pull event data and bind it to the picked element
              // Optimization: no need to pull if it's already marked as favorite(already cached)
              const cachedItem = favEvents.find(cached => cached.EventID === picked.EventId);
              if (cachedItem) {
                // favorite item so we already have its data ready and no need to pull anything
                ////console.log(`>>> Favorite event[${picked.EventId}], no need to pull`);
                picked.Title = cachedItem.Title;
                picked.ImageUrl = cachedItem.Image_List && cachedItem.Image_List.split('|').length > 0 ?
                cachedItem.Image_List.split('|')[0] : null;
                picked.Latitude = cachedItem.Latitude;
                picked.Longitude = cachedItem.Longitude;
              } else {
                ////console.log(`>>> Pulling event[${picked.EventId}]`);
                try {
                  const pulledItems = await this.dataController.getEventById(
                  { domainId: environment.cms.domainId, dataSyndication: false, eventId: picked.EventId }).toPromise();
                  if (pulledItems && pulledItems.length > 0) {
                    picked.Title = pulledItems[0].Title;
                    picked.ImageUrl = pulledItems[0].Image_List && pulledItems[0].Image_List.split('|').length > 0 ?
                    pulledItems[0].Image_List.split('|')[0] : null;
                    picked.Latitude = pulledItems[0].Latitude;
                    picked.Longitude = pulledItems[0].Longitude;
                    this.favoritesService.favoriteEvent(pulledItems[0]);
                  } else {
                    // can't pull data, let's ignore it and keep going
                    console.warn(`Can not pull event: ${picked.EventId}`);
                  }
                } catch (error) {
                  // can't pull data, let's ignore it and keep going
                  console.error(error);
                }
              }
            }
          });
        });
      }

    }
  }

  public async stopChanged(event: {
    action: string,
    dayId: number,
    dayIndex: number,
    stopId: number,
    listingId?: number,
    eventId?: number,
    startTime?: string,
    endTime?: string
  }) {
    const itineraryId = this.route.snapshot.params.id;
    if (event.action === 'delete') {
      // user click under 'delete listing' button
      await this.loading.presentLoading();

      // update the UI by removing the listing/event from the list
      // don't know why the UI don't get refreshed after pulling the updated itinerary
      if (this.itinerary.ItineraryDays) {
        const itineraryDay = this.itinerary.ItineraryDays.find(item => item.Id === event.dayId);
        if (itineraryDay && itineraryDay.ItineraryItems && itineraryDay.ItineraryItems.length > 0) {
          const positionToDelete = itineraryDay.ItineraryItems.findIndex(item => item.Id === event.stopId);
          if (positionToDelete >= 0) {
            ////console.log(`>>> Removing item at ${positionToDelete}`);
            itineraryDay.ItineraryItems.splice(positionToDelete, 1);
          } else {
            console.error(`>>> Can't update the UI, no itinerary listing/event[${event.stopId}] found`);
          }
        } else {
          console.error(`>>> Can't update the UI, no day[${event.dayId}] found`);
        }
      } else {
        console.error(`>>> Can't update the UI, no days available`);
      }

      this.itineraryBuilderStopClientService.deleteItemFromDay(itineraryId, event.dayId, event.stopId).then(response => {
        ////console.log(`>>> Deleted: ${response.wasSuccessful}`);
        this.loading.dismiss();
        if (response.wasSuccessful) {
          // do nothing
        } else {
          this.alertService.presentInfoAlert('Can\'t delete this listing at this moment', null, [{ text: 'Dismiss' }]);
        }
      }).catch(err => {
        console.error(err);
        this.loading.dismiss();
        this.alertService.presentInfoAlert('Can\'t delete this listing at this moment', null, [{ text: 'Dismiss' }]);
      });
    } else if (event.action === 'edit') {
      await this.itineraryBuilderStopClientService.editItemToDay(
        itineraryId, event.dayId, event.stopId, event.listingId, event.eventId, event.startTime, event.endTime);
    }
  }

  public async dayChanged(event: { action: string, dayId: number, dayIndex: number, date?: string }) {
    const itineraryId = this.route.snapshot.params.id;
    if (event.action === 'delete') {
      await this.itineraryBuilderDayClientService.deleteDay(itineraryId, event.dayId);
    } else if (event.action === 'edit') {
      await this.itineraryBuilderDayClientService.editDay(itineraryId, event.dayId, event.date);
    }
    await this.loadItinerary();
  }

  async onAddPresetItineraryTap() {

    // window.open(`mailto:${this.itinerary.Email}?subject=Hello&body=Take a look to my itinerary`, '_blank');
    let link = `mailto:${this.itinerary.Email}?subject=Hello&body=Take a look to my itinerary %0D%0A  %0D%0A`;
    this.itinerary.ItineraryDays.forEach((days, idx) => {
      link += `Day ${idx+1}: ${ moment(days.ItineraryDayDate).format("MMM/D/YYYY")}: %0D%0A`;
      days.ItineraryItems.forEach(item => {
        link += `${item.Title} %0D%0A`
        link += `${item.StartTime}-${item.EndTime} %0D%0A`;
        // link += `<img src="${item.ImageUrl}"> %0D%0A`
        // link += `<html><a href="http://www.google.com/maps/place/${item.Latitude},${item.Longitude}">See directions</a></html> %0D%0A %0D%0A`;
        // link += `<hr> %0D%0A %0D%0A %0D%0A %0D%0A`;
      })
      // mailto:sf@trueo.com?subject=test&body=testhola
    })
    ////console.log(link)
    window.location.href = link;
  }
  async onShareTap(t: string, description?: string, website?: string) {
    let myNavigator: any;
    myNavigator = window.navigator;
    if (myNavigator && myNavigator.share) {
      myNavigator.share({
        title: t,
        text: description ? `${t}\n${description}` : t,
        url: website,
      });
    } else {
      window.alert('Can not share at this moment');
    }
  }

  public async onNewDayDateChanged(date: any) {
    await this.loading.presentLoading();
    const year: string = date.year.text;
    const month: string = date.month.value < 10 ? '0' + date.month.value.toString(): date.month.value.toString();
    const day: string = date.day.text;
    const dateString = year + '/' + month + '/' + day;
    const dateObject = new Date(dateString);
    dateObject.setHours(0, 0, 0, 0);
    const isoString = dateObject.toISOString();
    await this.addNewDay(isoString);
    this.datePicker = null;
    return this.loading.dismiss();
  }

  async addNewDay(isoDate: string) {
    const itineraryId = this.route.snapshot.params.id;
    await this.itineraryBuilderDayClientService.addDay(parseInt(itineraryId, 10), isoDate);
    await this.loadItinerary();
  }

  public trackBy(index: number, item: { Id: string }) {
    if (!item) {
      return null;
    }
    return item.Id;
  }

  private createDefaultDay() {
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    const isoString = todayDate.toISOString();
    this.addNewDay(isoString);
  }

}
