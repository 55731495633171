import { Observable, Subscription } from 'rxjs';

import { AccessTokenClientService } from './access-token-client.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessagesSdkAccessTokenUtilsService } from './messages-sdk-access-token-utils.service';
import { environment } from '@env/environment';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { tap } from 'rxjs/operators'
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TokenControllerService {

  private BASE_URL = environment.messagesSDK.base_url;
  token = null;

  constructor(
    private accessTokenService: AccessTokenClientService,
    private http: HttpClient,
    private afMessaging: AngularFireMessaging,
    private messagesSDKUtils: MessagesSdkAccessTokenUtilsService) {

  }

  async registerDeviceToken(deviceToken: string) {
    const accessToken = await this.accessTokenService.getToken();
    const tokenRegistrationUrl = `${this.BASE_URL}/api/v3/application/devices/android`;
    return this.http.post(tokenRegistrationUrl, {
      Token: deviceToken,
      Platform: 'Android',
      Version: `PWA`
    }, {
      headers: this.messagesSDKUtils.getAccessTokenHeader(accessToken)
    }).pipe(take(1)).toPromise();
  }

  async registerDeviceTokenIos(deviceToken: string) {
    const accessToken = await this.accessTokenService.getToken();
    const tokenRegistrationUrl = `${this.BASE_URL}/api/v3/application/devices/ios`;
    return this.http.post(tokenRegistrationUrl, {
      Token: deviceToken,
      Platform: 'IOS',
      Version: `PWA`
    }, {
      headers: this.messagesSDKUtils.getAccessTokenHeader(accessToken)
    }).pipe(take(1)).toPromise();
  }

  requestPermission() {
    return this.afMessaging.requestToken.pipe(
      tap(token => {
        //console.log('Store token to server: ', token);
      })
    );
  }

  getMessages() {
    return this.afMessaging.messages;
  }

  deleteToken() {
    if (this.token) {
      this.afMessaging.deleteToken(this.token);
      this.token = null;
    }
  }

}
