import { HttpClient, HttpParams } from '@angular/common/http';

import { CoreAuthLibClientService } from '@app/core-auth-lib/services/core-auth-lib-client.service';
import { Injectable } from '@angular/core';
import { ItineraryBuilderUtilsService } from './itinerary-builder-utils.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ItineraryBuilderDayClientService {

  private BASE_URL = environment.itineraryBuilderSDK.base_url;
  constructor(
    private http: HttpClient,
    private coreAuthLibClientService: CoreAuthLibClientService,
    private utils: ItineraryBuilderUtilsService
  ) { }

  /**
   * @param itineraryId The itinerary id as a number
   * @param dayDate Date must be in 'yyyy-MM-dd'T'HH:mm:ss' format
   */
  async addDay(itineraryId: number, dayDate: string) {
    try {
      const token = await this.coreAuthLibClientService.getToken();
      const headers = this.utils.getAuthorizationTokenHeaders(token);
      const addDayUrl = `${this.BASE_URL}/api/v1/itineraries/${itineraryId}/days`;
      const requestObservable = this.http.post<{
        WasSuccessful: boolean
      }>(addDayUrl, {ItineraryDayDate: dayDate}, { headers });
      return new Promise<{ wasSuccessful: boolean }>((resolve, reject) => {
        requestObservable.subscribe(result => {
          if (result) {
            resolve({ wasSuccessful: result.WasSuccessful });
          } else {
            reject('Unknow error');
          }
        });
      });
    } catch (error) { throw error; }

  }

  /**
   * @param itineraryId The itinerary id as a number
   * @param dayId Date must be in 'yyyy-MM-dd'T'HH:mm:ss' format
   */
  async deleteDay(itineraryId: number, dayId: number) {
    try {
      const token = await this.coreAuthLibClientService.getToken();
      const headers = this.utils.getAuthorizationTokenHeaders(token);
      const deleteDayUrl = `${this.BASE_URL}/api/v1/itineraries/${itineraryId}/days/${dayId}`;
      const requestObservable = this.http.delete<{
        WasSuccessful: boolean
      }>(deleteDayUrl, { headers });
      return new Promise<{ wasSuccessful: boolean }>((resolve, reject) => {
        requestObservable.subscribe(result => {
          if (result) {
            resolve({ wasSuccessful: result.WasSuccessful });
          } else {
            reject('Unknow error');
          }
        });
      });
    } catch (error) { throw error; }

  }

  /**
   * @param itineraryId The itinerary id as a number
   * @param dayId Date must be in 'yyyy-MM-dd'T'HH:mm:ss' format
   */
  async editDay(itineraryId: number, dayId: number, dayDate: string) {
    try {
      const token = await this.coreAuthLibClientService.getToken();
      const headers = this.utils.getAuthorizationTokenHeaders(token);
      const editDayUrl = `${this.BASE_URL}/api/v1/itineraries/${itineraryId}/days/${dayId}`;
      const params = new HttpParams({
        fromObject: {
          ItineraryDayDate: dayDate
        }
      });
      const requestObservable = this.http.put<{
        WasSuccessful: boolean
      }>(editDayUrl, params, { headers });
      return new Promise<{ wasSuccessful: boolean }>((resolve, reject) => {
        requestObservable.subscribe(result => {
          if (result) {
            resolve({ wasSuccessful: result.WasSuccessful });
          } else {
            reject('Unknow error');
          }
        });
      });
    } catch (error) { throw error; }
  }
}
