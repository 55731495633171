import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { CoreAuthLibClientService } from '@app/core-auth-lib/services/core-auth-lib-client.service';
import { Injectable } from '@angular/core';
import { SecureSessionStorerService } from '@app/core-auth-lib/services/secure-session-storer.service';
import { SecureUserRepositoryService } from './secure-user-repository.service';
import { UserApiUser } from './User';
import { environment } from '@env/environment';
import { take } from 'rxjs/operators';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class UsersApiService {

  public isUserLoggedIn$: BehaviorSubject<boolean>;

  private BASE_URL = environment.coreAuthLib.base_url;
  constructor(
    private http: HttpClient,
    private coreAuth: CoreAuthLibClientService,
    private storage: Storage,
    private userRepository: SecureUserRepositoryService,
    private sessionStorer: SecureSessionStorerService,
  ) {
    this.isUserLoggedIn$ = new BehaviorSubject<boolean>(false);
  }

  async createAccount(params: {
    email: string,
    password: string,
    skipActivationFlow: boolean,
    additionalFields?: Map<string, string>,
    duty?: string
  }): Promise<{ Id: number }> {
    try {
      ////console.log(`PARAMS ${params}`);
      ////console.log(JSON.stringify(params));
      const t = await this.coreAuth.getApplicationToken();
      ////console.log(`App token ${t}`);
      const url = `${this.BASE_URL}/api/v1/users`;
      const requestHeaders = this.getHeaders(t.tokenType, t.accessToken);
      const af = [];
      if (params.additionalFields) {
        params.additionalFields.forEach((value, key) => {
          if (key) {
            af.push({
              Name: key,
              Value: value,
            });
          }
        });
      }

      const body = {
        Email: params.email,
        Password: params.password,
        SkipActivationFlow: String(params.skipActivationFlow),
        Duty: params.duty,
        AdditionalFields: af,
      };
      const response = this.http.post<{ Id: number }>(url, body, { headers: requestHeaders }).pipe(take(1)).toPromise();
      return response;
    } catch (error) {
      throw error;
    }
  }

  async login(params: { email: string, password: string }): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.coreAuth.getApplicationToken().then(t => {
        const url = `${this.BASE_URL}/api/v1/authentication/credentials`;
        const requestHeaders = this.getHeaders(t.tokenType, t.accessToken);

        const body = new HttpParams({
          fromObject: {
            grant_type: 'user',
            client_id: String(environment.cms.domainId),
            username: params.email,
            password: params.password,
          }
        });

        this.http.post<{
          access_token: string;
          token_type: string;
          refresh_token: string;
          expires_at: string;
          issued_at: string;
        }>(url, body, { headers: requestHeaders }).subscribe(
          async response => {
            await this.coreAuth.storeSession(response);
            await this.sessionStorer.setSessionType('user');
            await this.userRepository.setLoginWithInHouse();
            this.isUserLoggedIn$.next(true);
            resolve(true);
          },
          err => { reject(err); }
        );
      }).catch(err => {
        resolve(err);
      });
    });
  }

  async appleLogin(params: { idToken: string, duty?: string }): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.coreAuth.getApplicationToken().then(t => {
        const url = `${this.BASE_URL}/api/v1/authentication/apple`;
        const requestHeaders = this.getHeaders(t.tokenType, t.accessToken);

        const duty = (params.duty) ? params.duty : null;
        const body = {
          Token: params.idToken,
          Duty: duty,
        };

        this.http.post<{
          access_token: string;
          token_type: string;
          refresh_token: string;
          expires_at: string;
          issued_at: string;
        }>(url, body, { headers: requestHeaders }).subscribe(
          async response => {
            await this.coreAuth.storeSession(response);
            await this.sessionStorer.setSessionType('user');
            await this.userRepository.setLoginWithApple();
            this.isUserLoggedIn$.next(true);
            resolve(true);
          },
          err => { reject(err); }
        );
      }).catch(err => {
        resolve(err);
      });
    });
  }

  async facebookLogin(params: { accessToken: string, duty?: string }): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.coreAuth.getApplicationToken().then(t => {
        const url = `${this.BASE_URL}/api/v1/authentication/facebook`;
        const requestHeaders = this.getHeaders(t.tokenType, t.accessToken);

        const duty = (params.duty) ? params.duty : null;
        const body = {
          AccessToken: params.accessToken,
          Duty: duty,
        };

        this.http.post<{
          access_token: string;
          token_type: string;
          refresh_token: string;
          expires_at: string;
          issued_at: string;
        }>(url, body, { headers: requestHeaders }).subscribe(
          async response => {
            await this.coreAuth.storeSession(response);
            await this.sessionStorer.setSessionType('user');
            await this.userRepository.setLoginWithFacebook();
            this.isUserLoggedIn$.next(true);
            resolve(true);
          },
          err => { reject(err); }
        );
      }).catch(err => {
        resolve(err);
      });
    });
  }

  async googleLogin(params: { idToken: string, duty?: string }): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.coreAuth.getApplicationToken().then(t => {
        const url = `${this.BASE_URL}/api/v1/authentication/google`;
        const requestHeaders = this.getHeaders(t.tokenType, t.accessToken);

        const duty = (params.duty) ? params.duty : null;
        const body = {
          Token: params.idToken,
          Duty: duty,
        };

        this.http.post<{
          access_token: string;
          token_type: string;
          refresh_token: string;
          expires_at: string;
          issued_at: string;
        }>(url, body, { headers: requestHeaders }).subscribe(
          async response => {
            await this.coreAuth.storeSession(response);
            await this.sessionStorer.setSessionType('user');
            await this.userRepository.setLoginWithGoogle();
            this.isUserLoggedIn$.next(true);
            resolve(true);
          },
          err => { reject(err); }
        );
      }).catch(err => {
        resolve(err);
      });
    });
  }

  async logout(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      this.coreAuth.deleteSession();
      this.sessionStorer.setSessionType('application').then(async result => {
        await this.userRepository.clear();
        this.isUserLoggedIn$.next(false);
        resolve();
      }).catch(err => {
        reject(err);
      });
    });
  }

  async resetPassword(params: { email: string }): Promise<{ WasSuccessful: boolean }> {
    return new Promise<{ WasSuccessful: boolean }>((resolve, reject) => {
      this.coreAuth.getApplicationToken().then(t => {
        const url = `${this.BASE_URL}/api/v1/passwords/reset-token`;
        const requestHeaders = this.getHeaders(t.tokenType, t.accessToken);

        const body = {
          Email: params.email
        };

        this.http.post<{ WasSuccessful: boolean }>(url, body, { headers: requestHeaders }).subscribe(
          response => { resolve(response); },
          err => { reject(err); }
        );
      }).catch(err => {
        reject(err);
      });
    });
  }

  async getUser(): Promise<UserApiUser> {
    try {
      const t = await this.coreAuth.getToken();
      const url = `${this.BASE_URL}/api/v1/users/me`;
      const requestHeaders = this.getHeaders(t.tokenType, t.accessToken);
      const user = await this.http.get<UserApiUser>(url, { headers: requestHeaders }).pipe(take(1)).toPromise();
      await this.userRepository.set(user);
      this.isUserLoggedIn$.next(true);
      return user;
    } catch (error) {
      this.isUserLoggedIn$.next(false);
      throw error;
    }
  }

  async isLoggedIn(): Promise<boolean> {
    try {
      const session = await this.sessionStorer.sessionType();
      if (session && session === 'user') {
        const user = await this.userRepository.get();
        if (user && user.Id && user.Email) {
          this.isUserLoggedIn$.next(true);
          return true;
        } else {
          this.isUserLoggedIn$.next(true);
          return false;
        }
      } else {
        this.isUserLoggedIn$.next(true);
        return false;
      }
    } catch (error) {
      this.isUserLoggedIn$.next(false);
      throw error;
    }
  }

  async isLogged(){
    const session = await this.storage.get("user_email");
    if (session){
      return true;
    }
    return false
  }

  async changePassword(params: { password: string }): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.coreAuth.getToken().then(t => {
        const url = `${this.BASE_URL}/api/v1/users/me/password`;
        const requestHeaders = this.getHeaders(t.tokenType, t.accessToken);

        const body = {
          Password: params.password
        };

        this.http.put<boolean>(url, body, { headers: requestHeaders }).subscribe(
          response => { resolve(response); },
          err => { reject(err); }
        );
      }).catch(err => {
        reject(err);
      });
    });
  }

  async editUser(params: {
    additionalFields: Map<string, string | null>,
    duty?: string
  }): Promise<{ WasSuccessful: boolean }> {
    return new Promise<{ WasSuccessful: boolean }>((resolve, reject) => {
      this.coreAuth.getToken().then(t => {
        const url = `${this.BASE_URL}/api/v1/users/me`;
        const requestHeaders = this.getHeaders(t.tokenType, t.accessToken);

        const af = [];
        if (params.additionalFields) {
          params.additionalFields.forEach((value, key) => {
            if (key) {
              af.push({
                Name: key,
                Value: value,
              });
            }
          });
        }

        const duty = (params.duty) ? params.duty : null;
        const body = {
          Duty: duty,
          AdditionalFields: af,
        };
        this.http.put<{ WasSuccessful: boolean }>(url, body, { headers: requestHeaders }).subscribe(
          response => { resolve(response); },
          err => { reject(err); }
        );
      }).catch(err => {
        reject(err);
      });
    });
  }

  async updateUserImage(file: any) {
    let fileToUpload: any;
    if (typeof file === 'string') {
      fileToUpload = await this.http.get(file).pipe(take(1)).toPromise();
    } else {
      fileToUpload = file;
    }
    return new Promise<{ url: string }>(async (resolve, reject) => {
      try {
        const t = await this.coreAuth.getToken();
        const formData: FormData = new FormData();
        formData.append('file', fileToUpload);
        const headers = new HttpHeaders({
          Authorization: `${t.tokenType} ${t.accessToken}`,
          Accept: 'application/json'
        });
        const uploadUrl = `${this.BASE_URL}/api/v1/users/me/profile-image`;
        this.http.post<{
          url: string
        }>(uploadUrl, formData, {
          headers
        }).subscribe(
          response => { resolve(response); },
          err => { reject(err); }
        );
      } catch (error) {
        reject(error);
      }
    });
  }

  async subscribe(params: {
    fullname: string,
    email: string,
    country: string,
    state: string,
    zip: string,
  }): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.coreAuth.getApplicationToken().then(async t => {
        const url =  `${environment.pwaAPI.base_url}?domainId=${environment.cms.domainId}&fn=submitform&formId=${environment.cms.formId}&fullname=${params.fullname}&email=${params.email}&country=${params.country}&state=${params.state}&zip=${params.zip}`;
        const requestHeaders = this.getHeaders(t.tokenType, t.accessToken);

        const user = await this.http.get<UserApiUser>(url, { headers: requestHeaders }).pipe(take(1)).toPromise();
        // resolve(user)
        // this.http.get<{
        //   access_token: string;
        //   token_type: string;
        //   refresh_token: string;
        //   expires_at: string;
        //   issued_at: string;
        // }>(url, { headers: requestHeaders }).subscribe(
        //   async response => {
        //     await this.coreAuth.storeSession(response);
        //     await this.sessionStorer.setSessionType('user');
        //     await this.userRepository.setLoginWithGoogle();
        //     this.isUserLoggedIn$.next(true);
        //     resolve(true);
        //   },
        //   err => { reject(err); }
        // );
      }).catch(err => {
        resolve(err);
      });
    });
  }

  //https://webservice2.mobimanage.com/json.aspx?domainId=2350&fn=submitform&formId=158&fullname=Anthony&email=atran@trueomni.com&country=US&state=AZ&zip=85383

  private getHeaders(tokenType: string, accessToken: string): HttpHeaders {
    return new HttpHeaders({
      Authorization: `${tokenType} ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
  }

}
